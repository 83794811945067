import moment from 'moment'
import { Link } from 'react-router-dom'
import { EnvelopeIcon, EnvelopeOpenIcon } from '@heroicons/react/24/outline'

import Card from 'components/ui/Card'
import { getEnqColour } from 'util/helpers'
import RegPlate from 'components/EnquiryColumn/RegPlate'
import { useEffect, useState } from 'react'

const EnquiryCard = ({ enq, eta, isGlobal }) => {
  const statusColor = getEnqColour(enq.Status)
  const handleClick = () => {
    //setSelectedEnq(enq.EnquiryId)
  }
  const [isBackordered, setIsBackordered] = useState(false)
  useEffect(() => {
    const checkBackorderStatus = async () => {
      try {
        const response = await fetch(
          `https://melk.connectplus.parts/api/connectplus/backordered/check/${enq.EnquiryId}`
        )
        const json = await response.json()
        console.log('Backorder status:', json.exists)
        setIsBackordered(json.exists)
      } catch (error) {
        console.error('Error checking backorder status:', error)
      }
    }
    checkBackorderStatus()
  }, [enq.EnquiryId])

  const CardContent = () => (
    <Card hoverable={!isGlobal}>
      <div className="flex items-center space-x-2">
        <div style={{ display: 'flex', minHeight: 100, flexDirection: 'column', justifyContent: 'space-evenly' }} >
          {isBackordered && (
            <div style={styles.backorderBadge}>
              <h2 style={styles.backorderText}>ON BACK ORDER</h2>
            </div>
          )}
          <RegPlate size={1.2}>{enq.Reg}</RegPlate>
        </div>
        <div className="flex-1">
          <div className="text-xl font-bold uppercase">{enq.Parts}</div>
          <div className="italic uppercase">{enq.Notes}</div>
        </div>
      </div>

      <div className="w-full my-3 h-[3px]" style={{ backgroundColor: statusColor }} />

      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-1 text-xl">
          {enq.GarageUnread ? (
            <EnvelopeOpenIcon className="w-6 h-6" />
          ) : (
            <EnvelopeIcon className="w-6 h-6" />
          )}
          <div className="font-thin tracking-wide">
            {enq.MessageCount}/({enq.GarageUnread})
          </div>
        </div>

        <div className="text-lg font-medium uppercase">
          {enq.Status === 'Ordered' && eta ? (
            <div>
              {enq.Status} | ETA: {moment(eta).format('DD/MM/YY HH:mm')}
            </div>
          ) : (
            <div>{enq.Status}</div>
          )}
        </div>

        <div className="font-thin tracking-wide">
          {moment(enq.DatetimeCreated).format('DD/MM')}
        </div>
      </div>
    </Card>
  )

  return isGlobal ? (
    <div className="cursor-not-allowed">
      <CardContent />
    </div>
  ) : (
    <Link to={`/enquiries/${enq.EnquiryId}`} onClick={handleClick}>
      <CardContent />
    </Link>
  )
}

const styles = {
  backorderBadge: {
    backgroundColor: '#FF9800',
    borderRadius: 4,
    padding: '2px 6px',
  },
  backorderText: {
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Ubuntu, sans-serif',
    fontWeight: '700',
    fontSize: 12
  }
}

export default EnquiryCard
