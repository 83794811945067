import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { MagnifyingGlassIcon, CalendarDaysIcon, PlusIcon } from '@heroicons/react/24/outline'

import * as api from 'util/api'
import InputWithButton from 'components/ui/InputWithButton'
import { Button } from 'components/gsys-ui'
import usePaginated from 'hooks/usePaginated'
import NiceModal from '@ebay/nice-modal-react'
import { EnquiriesContext } from 'context/EnquiriesContext'
import { useBulletins } from 'util/queries'
import moment from 'moment'
import Spinner from '../ui/Spinner'
import { GlobalContext } from 'context/GlobalContext'

const NewsColumn = ({ onSelectBulletin }) => {
    const { cinfo, currencySymbol, uinfo } = useContext(GlobalContext)
    const navigate = useNavigate()

    const { isLoading, isError, data: news, error } = useBulletins()

    if (isError || !news) return <div className="col-span-4">Sorry - Couldn't find any news for you 😔</div>

    const handleBulletinClick = (item) => {
        // Call the function passed from parent to set the selected bulletin
        onSelectBulletin(item)

        // Optionally update the URL to include the bulletin ID
        navigate(`/news/${item.BulletinId}`)
    }

    return (
        <div className="flex overflow-hidden flex-col col-span-4 h-full">
            <div className="flex mb-5 space-x-2 flex-0">
                YOUR BULLETINS
            </div>
            {
                isLoading ? (
                    <div className="inline-block mx-auto"><Spinner /></div>
                ) : (
                    <div className="overflow-y-scroll flex-1 space-y-2">
                        {
                            news.data.map((item, index) => {
                                return (
                                    <div
                                        key={index.toString()}
                                        onClick={() => handleBulletinClick(item)}
                                        className="card cursor-pointer"
                                    >
                                        <div className="flex flex-row items-center">
                                            <img
                                                src={item.ThumbnailPath}
                                                className="thumbnail"
                                                alt="thumbnail"
                                            />
                                            <div className="ml-2">
                                                <h3 className="card-title">{item.Title}</h3>
                                                <p className="text">
                                                    EXPIRES: {moment(item.ExpiryTime).format('DD/MM/YYYY')}
                                                </p>
                                                <p className="text">
                                                    RECEIVED: {moment(item.UploadedAt).format('DD/MM/YYYY')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    )
}

export default NewsColumn