import Card from 'components/ui/Card'
import { useEffect, useState } from 'react';

const EnqCancelled = ({ enqId }) => {
  const [cantSupplyReason, setCantSupplyReason] = useState(null);

  const loadCantSupplyReason = async () => {
    try {
      const response = await fetch(`https://melk.connectplus.parts/api/salesassist/cplusassist/cantsupply/${enqId}`);
      const data = await response.json();
      if (data && data.length > 0) {
        setCantSupplyReason(data[0].Reason);
      }
    } catch (error) {
      console.error('Error loading cant supply reason:', error);
    }
  };

  useEffect(() => {
    loadCantSupplyReason();
  }, [enqId]);

  return (
    <Card>
      <div className="text-lg text-center">          {cantSupplyReason ? "THIS ENQUIRY CANNOT BE SUPPLIED" : "THIS ENQUIRY HAS BEEN CANCELLED"}
      </div>
      <div>
        {cantSupplyReason ? (
          <h3 style={{ fontFamily: 'Ubuntu_300Light', textAlign: 'center', fontSize: 16, color: 'grey' }}>
            Reason: {cantSupplyReason}
          </h3>
        ) : (
          <h3 style={{ fontFamily: 'Ubuntu_300Light', textAlign: 'center', fontSize: 16, color: 'grey' }}>
            Please contact us if you want to discuss this enquiry.
          </h3>
        )}
      </div>
    </Card>
  )
}

export default EnqCancelled