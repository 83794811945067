import { useParams } from 'react-router-dom'
import Card from 'components/ui/Card'
import RegPlate from 'components/EnquiryColumn/RegPlate'
import { getEnqColour, getEnqIcon } from 'util/helpers'
import EnqArchived from 'components/DetailsColumn/EnqArchived'
import EnqCancelled from 'components/DetailsColumn/EnqCancelled'
import EnqOrdered from 'components/DetailsColumn/EnqOrdered'
import EnqQuoted from 'components/DetailsColumn/EnqQuoted'
import EnqWaiting from 'components/DetailsColumn/EnqWaiting'
import { useEnquiries, useEnquiriesG } from 'util/queries'

const DetailsColumn = () => {
  const { isLoading: isLoadingMy, isError: isErrorMy, data: myEnqs = [], error: errorMy } = useEnquiries()
  const { isLoading: isLoadingGlobal, isError: isErrorGlobal, data: globalEnqs = [], error: errorGlobal } = useEnquiriesG()
  const { enqId } = useParams()

  if (!enqId) return <div className="col-span-5 italic">Please select an enquiry.</div>
  if (isLoadingMy || isLoadingGlobal) return <div className="col-span-5">Loading...</div>
  if (isErrorMy || isErrorGlobal) return <div className="col-span-5">{JSON.stringify(isErrorMy ? errorMy : errorGlobal)}</div>

  // Combine both sets of enquiries
  const allEnqs = [...myEnqs, ...globalEnqs]
  const selectedEnq = allEnqs.find(el => el.EnquiryId === enqId)

  if (!selectedEnq) return <div className="col-span-5 italic">Enquiry not found.</div>

  return (
    <div className="col-span-5 space-y-2" style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <Card style={{}}>
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <RegPlate size={1.5}>{selectedEnq.Reg}</RegPlate>
            <EnqStatus status={selectedEnq.Status} />
          </div>
          <div>
            <div className="text-3xl font-bold uppercase">{selectedEnq.Parts}</div>
            <div className="text-xl">{selectedEnq.Notes}</div>
          </div>
        </div>
      </Card>
      <div
        id="listCont"
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          flex: 4,
          minHeight: 'min-content'
        }}
      >
        {selectedEnq.Status === 'Waiting' && <EnqWaiting enqId={enqId} />}
        {selectedEnq.Status === 'Order' && <EnqWaiting enqId={enqId} />}
        {selectedEnq.Status === 'Garage Confirmed' && <EnqOrdered enqId={enqId} />}
        {selectedEnq.Status === 'Ordered' && <EnqOrdered enqId={enqId} />}
        {selectedEnq.Status === 'Quoted' && <EnqQuoted enqId={enqId} />}
        {selectedEnq.Status === 'Cancelled' && <EnqCancelled enqId={enqId} />}
        {selectedEnq.Status === 'Archived' && <EnqArchived enqId={enqId} />}
      </div>
    </div>
  )
}

const EnqStatus = ({ status }) => {
  const enqColour = getEnqColour(status)
  const EnqIcon = getEnqIcon(status)
  if (status === 'Garage Confirmed') status = 'Confirmed'

  return (
    <div
      className="flex items-center px-6 py-3 h-full text-3xl font-bold text-white uppercase rounded-md"
      style={{
        backgroundColor: enqColour
      }}
    >
      <EnqIcon className="mr-2 w-10 h-10" />
      {status}
    </div>
  )
}

export default DetailsColumn