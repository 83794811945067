import { useParams } from 'react-router-dom'
import { useState } from 'react'

import NewsColumn from 'components/NewsColumn/NewsColumn'
import NewsDetailsColumn from 'components/NewsDetailsColumn/NewsDetailsColumn'
import '../util/modals'

const News = () => {
  const { newsId } = useParams()
  // State to hold the selected bulletin
  const [selectedBulletin, setSelectedBulletin] = useState(null)

  return (
    <div className="grid grid-cols-12 gap-x-4 p-3 w-full max-w-full h-full">
      <div className="col-span-4">
        <NewsColumn onSelectBulletin={setSelectedBulletin} />
      </div>
      {(newsId || selectedBulletin) && (
        <div className="col-span-8">
          <NewsDetailsColumn news={selectedBulletin} />
        </div>
      )}
    </div>
  )
}

export default News;