import axios from 'axios'
import haxios from './haxios'
import { BASE_URL } from './config'
import moment from 'moment'
import { blobToBase64, objectToFormData } from './helpers'
import { useContext } from 'react'

const melkBaseUrl = 'https://melk.connectplus.parts'
export const getApirAddr = async (cid) => {
  //alert(JSON.stringify(cid))
  const res = await haxios.get(`https://autommapir.connectplus.parts/api/getCompany/${cid}`)
  //alert(JSON.stringify(res))
  return res?.data
}


export const getCompanyInfo = async () => {
  const res = await haxios.get(BASE_URL + '/api/user')
  return res?.data
}

export const getUserInfo = async () => {
  const res = await haxios.get(BASE_URL + '/api/connectplus/garageuser')
  return res?.data
}

export const getImageFromBlobId = async (blobId) => {
  const res = await haxios.get(`${BASE_URL}/api/image/${blobId}`, { responseType: 'blob' })
  const blob = res.data
  const base64 = await blobToBase64(blob)
  return base64
}

export const getEnquiries = async (startDate, endDate) => {
  startDate = startDate || moment().add(-36, 'days').format('YYYY-MM-DD')
  endDate = endDate || moment().add(1, 'days').format('YYYY-MM-DD')

  const res = await haxios({
    url: BASE_URL + '/api/connectplus/enquiries',
    method: 'POST',
    //headers: { 'Content-type': 'application/json' },
    data: {
      StartDate: startDate,
      EndDate: endDate
    },
  })

  return res?.data
}


export const getCompanyEnquiries = async (startDate, endDate, uinfo) => {
  startDate = startDate || moment().add(-5, 'days').toISOString()
  endDate = endDate || moment().add(1, 'days').toISOString()

  const customerKeyCode = uinfo?.CustomerKeyCode // Get CustomerKeyCode from uinfo

  if (!customerKeyCode) {
    console.error('CustomerKeyCode is undefined!')
    throw new Error('CustomerKeyCode is missing!') // Prevent API call if missing
  }

  console.info('CustomerKeyCode:', customerKeyCode)

  const response = await haxios.get(`${melkBaseUrl}/api/connectplus/getFactorEnquiries?StartDate=${startDate}&EndDate=${endDate}&Acct=${customerKeyCode}`)
  console.info('API URL:', `${melkBaseUrl}/api/connectplus/getFactorEnquiries?StartDate=${startDate}&EndDate=${endDate}&Acct=${customerKeyCode}`)
  console.log('Company Enquiries Response:', response.data)

  return response.data
}



export const createEnquiry = (body) => {
  const bodyProcessed = {
    Status: body.Status,
    Notes: body.Notes,
    Parts: body.Parts,
    Reg: body.Reg.toUpperCase(),
    Consumables: body.Consumables === 'Consumables',
    Image: body.Image
  }

  return haxios({
    url: BASE_URL + '/api/connectplus/enquiry',
    method: 'POST',
    //headers: { 'Content-Type': 'multipart/form-data' },
    data: objectToFormData(bodyProcessed)
  })
}

export const cancelEnquiry = (enquiryId) => {
  return haxios({
    url: `${BASE_URL}/api/connectplus/enquiry/cancel/${enquiryId}`,
    method: 'PUT',
    //headers: { 'Content-type': 'application/json' }
  })
}

export const getEnquiryResponse = async (enquiryId) => {
  console.info('getting response data')
  const res = await haxios.get(`${BASE_URL}/api/connectplus/response/${enquiryId}`)
  console.info('response data: ', res)
  return res?.data
}

export const placeOrder = async (orderItems) => {
  try {
    const res = await haxios({
      url: BASE_URL + '/api/connectplus/response/conf',
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      data: JSON.stringify(orderItems)
    })
  } catch (e) {
    console.log(e)
  }
}

export const getMessages = async (enquiryId) => {
  const res = await haxios.get(`${BASE_URL}/api/connectplus/garage/messages/${enquiryId}/true`)
  return res.data.reverse()
}

export const sendMessage = async (enquiryId, msg) => {
  let body = {
    EnquiryID: enquiryId,
    Text: msg.text,
    Image: msg.image || undefined
  }

  await haxios({
    url: BASE_URL + '/api/connectplus/garage/message',
    method: 'POST',
    data: objectToFormData(body)
  })
}

const processImageUri = (uri) => {
  const fileName = uri.split('/').pop()
  const match = /\.(\w+)$/.exec(fileName);
  const type = match ? `image/${match[1]}` : `image`;
  return { uri, name: fileName, type }
}

export const activateLicense = async (devId, license) => {
  const body = {
    DeviceID: devId,
    EntryCode: license,
    DeviceDescription: `Desktop`,
    OneSignalID: devId
  }

  try {
    const res = await axios({
      url: BASE_URL + '/api/authenticate/mobile/activate',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: body
    })

    if (res.status !== 202) return false

    if (res?.data?.token) {
      return res.data.token
    }

    return false
  } catch (e) {
    console.log(e)
    return e
  }
}

export const enqCheckRun = async (enqs, apir) => {
  const data = {
    enqs: enqs.map((enq) => ({
      Acct: enq.CustomerKeyCode,
      EnquiryId: enq.EnquiryId
    }))
  }

  return haxios({
    url: `https://${apir}/api/run/checkIfOnRun`,
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    data: data,
    withBodyToken: true
  })
}

export const registerDevice = async (devInfo, apir) => {
  const data = {
    DateTime: moment(),
    DeviceId: devInfo.OneSignalId,
    UserId: devInfo.UserId,
    OneSignalId: devInfo.OneSignalId,
    CompanyId: devInfo.CompanyId,
    Acct: devInfo.Acct
  }
  //console.info('oooooooooooooooooooooooooo', data)
  return haxios({
    url: `https://${apir}/api/garage/addDevice/`,
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    data: data,
    withBodyToken: true
  })
}

export const getInTransit = async (acct, apir) => {
  const res = await haxios.get(`https://${apir}/api/run/getInTransit/${acct}`)
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
  console.info(`IN TRANSIT at ${currentTime}: `, res.data)
  return res.data
}

export const getDropInTransit = async (dropId, apir) => {
  return haxios.get(`https://${apir}/api/run/getTrackingDrops/${dropId}`)
}

export const getUserBulletins = async (userId, apir) => {
  console.info(`url endpoint: ${apir}`)
  // console.info('api fetch user bulletins', `https://${apir}/api/factor/news/userBulletins/${userId}`)
  // return haxios.get(`https://${apir}/api/factor/news/userBulletins/${userId}`)

  const url = `${apir}/api/promotions/${userId}`
  console.info('Fetching user bulletins from', url)
  try {
    return haxios.get(url)
  } catch (error) {
    console.error('Error fetching bulletins:', error)
    throw error
  }
}

export const getBulletinById = async (bId, apir) => {
  console.info('api bulletin by Id', `https://${apir}/api/factor/news/getBulletinById/${bId}`)
  return haxios.get(`https://${apir}/api/factor/news/getBulletinById/${bId}`)
}

export const getReceipts = async (acct, document, apir) => {
  console.info('api fetch receipts', `https://${apir}/api/garage/receipts?Acct=${acct}&Document=${document}`)
  return haxios.get(`https://${apir}/api/garage/receipts?Acct=${acct}&Document=${document}`)
}

export const getReturnPartsList = async (acct, apir) => {
  console.info('api fetch return parts', `https://${apir}/api/garage/returnparts/list/${acct}`)
  return haxios.get(`https://${apir}/api/garage/returnparts/list/${acct}`)
}

export const getPartList = async () => {
  try {
    const res = await haxios.get(`https://melk.connectplus.parts/api/connectplus/partlist`);
    // Assuming the API returns an array of objects: [{ PartGroup: string, PartList: string[] }]
    console.info("Parts list: ", res.data);
    return res;
  } catch (error) {
    console.error("Failed to fetch parts list:", error);
    throw new Error("Failed to fetch parts list");
  }
};



export const getQuoDetails = async (doc, apir) => {
  const response = await haxios.get(`${melkBaseUrl}/api/connectplus/getQuoDetails?document=${doc}`)
  console.log('Quote Details Response:', response.data)
  return response
}

export const getPartStocks = async (parts, apir) => {
  const response = await haxios.post(`${melkBaseUrl}/api/factor/stock`, {
    parts: parts
  })
  console.log('Part Stocks Response:', response.data)
  return response
}
