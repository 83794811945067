import { useState, useContext } from 'react'
import { MagnifyingGlassIcon, CalendarDaysIcon, PlusIcon } from '@heroicons/react/24/outline'
import InputWithButton from 'components/ui/InputWithButton'
import { Button } from 'components/gsys-ui'
import EnquiryCard from 'components/EnquiryColumn/EnquiryCard'
import NiceModal from '@ebay/nice-modal-react'
import { useEnquiries, useEnquiriesG } from 'util/queries'
import moment from 'moment'
import Spinner from '../ui/Spinner'
import { GlobalContext } from 'context/GlobalContext'

const EnquiryColumn = () => {
  const { uinfo } = useContext(GlobalContext) // Access uinfo from context
  const { isLoading: isLoadingMy, isError: isErrorMy, data: enqs, error: errorMy } = useEnquiries() // Fetch my enquiries
  const { isLoading: isLoadingGlobal, isError: isErrorGlobal, data: enqsG = [], error: errorGlobal } = useEnquiriesG() // Fetch global enquiries, fallback to empty array

  const [searchValue, setSearchValue] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [myEnqs, setMyEnqs] = useState(true)

  const toggleMyEnqs = () => setMyEnqs((prev) => !prev)

  if (isErrorMy) return <div className="col-span-4">{JSON.stringify(errorMy)}</div>
  if (isErrorGlobal) return <div className="col-span-4">{JSON.stringify(errorGlobal)}</div>

  // Log the data being fetched
  console.log("Global Enquiries Data:", enqsG);

  // Decide which data to display: either my enquiries or global enquiries
  const enquiriesToDisplay = myEnqs ? enqs : enqsG || [] // Show either my enquiries or global enquiries

  // Log the structure of individual enquiries to inspect the Date field
  const enqsSearched = searchValue
    ? enquiriesToDisplay.filter((enq) => {
      const str = `${enq.Reg.toLowerCase()}) ${enq.Status.toLowerCase()}) ${enq.Parts.toLowerCase()} ${enq.Notes.toLowerCase()}`
      const query = searchValue.toLowerCase()
      return str.includes(query)
    })
    : enquiriesToDisplay

  const enqsFilteredStart = startDate
    ? enqsSearched.filter((enq) => {
      console.log('Enquiry Date:', enq.Date); // Log enquiry Date
      return moment(enq.Date).isAfter(startDate); // Ensure Date field exists and is parsed correctly
    })
    : enqsSearched

  const enqsFilteredEnd = endDate
    ? enqsFilteredStart.filter((enq) => {
      console.log('Enquiry Date (End):', enq.Date); // Log enquiry Date
      return moment(enq.Date).isBefore(endDate); // Ensure Date field exists and is parsed correctly
    })
    : enqsFilteredStart

  console.log("Filtered Enquiries:", enqsFilteredEnd);

  // Ensure enqsFilteredEnd is always an array
  const enqsFinal = Array.isArray(enqsFilteredEnd) ? enqsFilteredEnd : []

  return (
    <div className="flex overflow-hidden flex-col col-span-4 h-full">
      <div className="flex mb-3 flex-0">
        <Button onClick={() => NiceModal.show('newEnquiryModal')}>
          <PlusIcon className="mr-2 w-5 h-5" />
          New Enquiry
        </Button>
        <Button onClick={toggleMyEnqs} className="ml-3">
          {myEnqs ? 'Show Global Enquiries' : 'Show My Enquiries'}
        </Button>
      </div>
      <div className="flex mb-5 space-x-2 flex-0">
        <InputWithButton
          placeholder="Search..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        >
          <MagnifyingGlassIcon className="w-5 h-5" />
        </InputWithButton>
        <Button onClick={() => NiceModal.show('dateFilterModal', { startDate, endDate, setStartDate, setEndDate })}>
          <CalendarDaysIcon className="w-5 h-5" />
        </Button>
      </div>

      {startDate && endDate ? (
        <div className="mb-2 italic text-center text-gray-500">Showing enquiries between {moment(startDate).format('DD/MM/YY')} and {moment(endDate).format('DD/MM/YY')}</div>
      ) : !startDate && endDate ? (
        <div className="mb-2 italic text-center text-gray-500">Showing enquiries before {moment(endDate).format('DD/MM/YY')}</div>
      ) : startDate && !endDate ? (
        <div className="mb-2 italic text-center text-gray-500">Showing enquiries after {moment(startDate).format('DD/MM/YY')}</div>
      ) : null}

      {isLoadingMy || isLoadingGlobal ? (
        <div className="inline-block mx-auto"><Spinner /></div>
      ) : (
        <div className="overflow-y-scroll flex-1 space-y-2">
          {enqsFinal.length > 0 ? (
            enqsFinal.map((enq) => {
              return <EnquiryCard
                key={enq.EnquiryId}
                enq={enq}
                isGlobal={!myEnqs}  // Pass isGlobal based on myEnqs state
              />
            })
          ) : (
            <div className="text-center text-gray-500">No enquiries found</div>
          )}
        </div>
      )}
    </div>
  )
}

export default EnquiryColumn

