import Spinner from '../ui/Spinner'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Card from 'components/ui/Card'
import moment from 'moment'

const NewsDetailsColumn = ({ news }) => {
    // If no news is passed, show a placeholder
    if (!news) {
        return (
            <div className="h-full flex items-center justify-center">
                <p className="text-gray-500">Select a bulletin to view details</p>
            </div>
        )
    }

    return (
        <div className="h-full flex flex-col">


            {/* PDF viewer */}
            {news.FilePath ? (
                <div className="flex-1 border rounded-md overflow-hidden">
                    <iframe
                        src={news.FilePath}
                        className="w-full h-full"
                        title={news.Title}
                    />
                </div>
            ) : (
                <div className="flex-1 flex items-center justify-center">
                    <p className="text-gray-500">No document available for this bulletin</p>
                </div>
            )}
        </div>
    )
}

export default NewsDetailsColumn;